// @import "static/css/common";

// @import "~bootstrap/scss/bootstrap-grid";
// @import "~bootstrap/scss/mixins";

body {
  font-weight: 100!important;
  // text-transform:uppercase;
}

.wedding-main-route-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  span {
    font-weight: 100;
  }

  .actions {

    display: flex;
    flex-direction: row;
    justify-content: center;

  }

}
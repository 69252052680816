.order-front-edit-container {

  padding-bottom: 250px;

  .order-edit-customer {
    text-align: left;

    .customer-container {
      table {
        tbody {
          tr:first-child {
            padding: 2px !important;
            border-top: 1px #ddd solid;
            th {
              padding: 5px 0 0 0;
            }
            td {
              padding: 5px 0 2px 0 !important;
            }
          }
          td {
            padding: 2px !important;
            border-top: 0 !important;
          }
        }
      }
    }
  }
}

.order-edit-billing {
  .billing-pref-list {
    .item-container {
      padding: 5px;

      .billing-pref-item {
        cursor: pointer;

        &.selected {

          .header {
            color: white;
            background-color: #337ab7;
            border-color: #337ab7;
          }
        }

        .header {
          background-color: #f5f5f5;
          border-color: #ddd;
          // &:extend(.panel-heading all);
        }

        .body {
          // &:extend(.panel-body all);
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          justify-content: center;

          span {
            min-height: 70px;
          }

          input {
            margin: 10px;
            width: initial;
            height: initial;
            cursor: pointer;
          }
        }
      }
    }
  }

}

.order-edit-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .alert {
    width: 100%;
    text-align: center;
  }
}



@import "./theme.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";


@mixin modal-fullscreen() {
  padding: 0 !important; // override inline padding-right added from js
  justify-content: center;
  align-items: center;
  display: flex!important;

  .modal-dialog {
    width: 90%;
    max-width: none;
    // height: 90%;
    margin: 0;

  }

  .modal-content {
    // height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
    max-height: 90vh;
  }

}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }
  }
}


@mixin fontFamily {
  font-family: "Lato", sans-serif!important;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Lato Light'), local('Lato-Light'), url(~static/fonts/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Lato Light'), local('Lato-Light'), url(~static/fonts/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;//     url(~app/static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2')
  // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  src: local('Lato Regular'), local('Lato-Regular'), url(~static/fonts/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  // src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  src: local('Lato Regular'), local('Lato-Regular'), url(~static/fonts/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  cursor: auto;
  // font-family: "Lato Regular", sans-serif!important;
  @include fontFamily;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
}

.input-group {
  padding: 5px 5px;

  &:last-of-type {
    padding-bottom: 10px;
  }


}

h1,h2,h3,h4 {
  @include fontFamily;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
}

h1  {
  font-size: 24px;
}

h2  {
  font-size: 20px;
}

h3  {
  font-size: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

.height-100p{
  height: 100%!important;
}

// .main-container {
//   // padding-top: 20px;
// }

// $screen-sm-min : 500px;

button {
  @extend .btn;
  @extend .btn-dark;
}


// @import (reference) "~bootstrap/less/bootstrap.less";

.template-edit-container {

  .template-edit-form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .CodeMirror {
      height: 600px;
      min-width: 800px;
    }

  }
}

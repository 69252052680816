// @import "~bootstrap/scss/bootstrap.scss";

.stripe-iban-element {
  font-family: "Lato", sans-serif !important;
  padding: 10px!important;
}

.bank-account-form-container {
  .input-group {
    margin-top: 10px;
  }

  .form-control {
    margin-bottom: 0px;
    font-family: "Lato", sans-serif !important;

    &.child-container {
      padding: 0;
    }
  }

  .child-container {
    padding: 10px;

    .child-item-container {
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 0px;
    }

    .row {
      padding-bottom: 10px;
    }
  }

  button {
    font-family: "Lato", sans-serif !important;
  }

  .child-item {
    margin-top: 10px;
    margin-bottom: 10px;

    input[type="radio"] {
      height: initial;
      width: initial;
    }

    .child-control {
      span {
        // @extend .visible-xs;
      }

      button[type="button"] {
        // @extend  .hidden-xs;
        line-height: inherit;
      }
    }

    &.selected {
      .select-display {
        background-color: #337ab7;
      }
    }
  }

  .bank-account-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .bank-account-valid-action {
      padding-top: 10px;
      padding-left: 15px;
    }

    .error-container {
      // &:extend(.alert all);
      // &:extend(.alert-danger all);
      width: 100%;
    }

    .action {
      padding-top: 10px;
      padding-left: 15px;

      // .spinner {
      //   @extend .spinner;
      // }
    }
  }

  .input-group-addon {
    // &:extend(.input-group-addon all);

    &.xs-hide {
      // .hidden-xs;
    }
  }

  .form-control {
    // &:extend(.form-control all);
  }

  div.form-control {
    height: 100%;
    padding-bottom: 15px;
  }

  .mentions {
    padding-top: 20px;

    span {
      font-size: 12px;
      font-style: italic;
    }
  }
}

.modal-bank-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding-bottom: 50px;
}

.modal-bank-account-item {
  // .make-xs-column(12);
  // .make-sm-column(8);
  // .well;
}

.invoice-edit-container {

  .input-group {
    margin-top:10px;
  }

  .form-control {
    margin-bottom: 0px;
    font-family: "Lato", sans-serif !important;

    &.child-container {
      padding: 0;
    }

  }
}

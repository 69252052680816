
// .notification {

  // .envelope {
  // }

  .choice-list {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    .choice-item {
      // width: 40px;
      padding: 20px;
    }
  }
// }
// @import "components/common/icons";
// @import (reference) "~bootstrap/less/bootstrap.less";

.hosting-provisioning-action-container {
  h1 {
    margin: 0;
  }

  .provisioning-action-form {
    // &:extend(.well all);
    height: 100%;
    display: flex;
    flex-direction: column;

    .form-row {
      // .make-row();
      // &:extend(.input-group all);
      height: 100%;;
      margin: 10px 0px;
    }
  }

  .loading-spin {
    // &:extend(.fa  all);
    // &:extend(.fa-loading  all);
  }

  .log-container {
    font-size: 12px;
    font-family: Courier;
    height: 400px;
    border: #CCC 1px solid;
    overflow: scroll;
  }
}
// @import "static/css/common";

@import "~bootstrap/scss/bootstrap-grid";

@mixin feature-container {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f8f9fa !important;
  // background: `url(${Vars.url_bucket_image}/${props.bgImage})
}

@mixin feature-title {
  font-size: 1.25rem;
  font-weight: 300;
  text-transform: capitalize;
}

.main-route-container {
  // display: flex;
  text-align: center;

  a {
    color: inherit;
  }

  button {
    // margin-top: 25px;
    // padding: 11px;
  }

  .main-bg {
    opacity: 0.9;
  }

  .with-cursor {
    cursor: pointer;
  }

  .div-welcome {
    height: 90vh;
    width: 100vw;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    div:first-child {
      height: 90vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    h1 {
      font-size: 48px;
      font-style: italic;
      text-transform: capitalize;
      font-weight: 300;
    }

    h2 {
      color: #ccc;
      font-weight: 300;
      padding: 0 10px;
    }

    button {
      // @extend .btn-lg;
      margin-top: 25px;
      // width: 150px;
      // height: 70px;
    }
  }

  .our-services {
    .card-focus {
      padding-top: 50px;
      font-size: 15px;
      font-weight: 200;

      .icon-carousel {
        font-size: 36px;
        color: black;
      }

      p {
        padding: 0;
        margin: 0;
      }

      .card-header {
        @include feature-title;
      }

      .card-body {
        min-height: 550px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(sm) {
          min-height: 445px;
        }
      }

      .card-spacer {
        padding-bottom: 50px;
      }

      h3 {
        font-size: 16px !important;
        padding-top: 15px;
        padding-bottom: 10px;
        margin: 0;
      }

      .tools,
      .refs {
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        .logos {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        .icon-ellipsis {
          font-size: 14px;
          cursor: pointer;
        }

        img {
          max-width: 70px;
          padding: 0 10px;

          @include media-breakpoint-up(sm) {
            max-width: 100px;
            padding: 0 10px;
          }
        }

        span {
          font-size: 46px;
          padding: 0 10px;
          color: #aaa;
        }
      }
    }

    &.padded-bottom {
      padding-bottom: 350px !important;
    }

    .call-for-action {
      padding-top: 20px;
    }

    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    padding-bottom: 150px;

    &.even {
      background-color: #ddd;
    }

    .icon {
      font-size: 16px;
      color: #aaa;
      padding-right: 10px;
    }

    h2 {
      font-size: 28px;
      padding-top: 25px;
      text-transform: capitalize;
    }

    h3 {
      font-size: 16px;
      padding-bottom: 25px;
      font-style: italic;
      // font-weight: bold;
      color: #aaa;
      // text-transform: capitalize;
    }

    .h5 {
      @include feature-title;
      padding-top: 10px;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @include media-breakpoint-up(sm) {
        text-align: left;
      }

      margin: 0;
      padding-left: 20px;
      min-height: 105px;
      li {
        font-size: 14px;
        list-style: none;
        padding-top: 5px;
      }
    }

    .card {
      @include media-breakpoint-up(sm) {
        flex: 1 0 33%;
        margin-bottom: 15px;
      }
      @include media-breakpoint-up(lg) {
        flex: 1 0 0%;
        margin-bottom: inherit;
      }

      .card-body {
        padding: .5rem 5px 1.25rem 5px;
      }

      &.with-footer {
        padding-bottom: 50px;
      }
    }

    .card-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      cursor: pointer;
    }

    .contact {
      display: flex;
      width: 150px;
      height: 150px;
      overflow: hidden;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding-top: 55px;

      img {
        width: 150px;
      }

      @include media-breakpoint-up(sm) {
        padding-top: 40px;
        width: 200px;
        height: 200px;

        img {
          width: 200px;
        }
      }
    }
  }

  .contact-container {
    .details {
      padding-left: 10px;
      padding-right: 5px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @include media-breakpoint-up(sm) {
        // padding-top: 40px;
        padding-left: 40px;
      }

      .span-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // padding-top: 5px;
        // padding-bottom: 5px;
      }

      .icon {
        padding-right: 5px;
      }

      span {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        padding-top: 4px;
      }

      a {
        color: inherit;
      }
    }
  }

  .footer {
    padding-bottom: 80px;

    .row {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
    }

    .vcard {
      font-size: 12px;
      padding-left: 20px;
    }

    .logo-img {
      width: 50px;
    }

    .logo-text {
      padding-left: 10px;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
    }
  }
}

// // @import (reference) "~bootstrap/less/bootstrap.less";
.firewall-container {
  .position {
    // width: 60px !important;
  }

  .from {
    // width: 120px !important;
  }

  .port {
    // width: 60px !important;
  }

  .port-range {
    // width: 60px !important;
  }

  .port-range-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .icon-trash {
    color: #dc3545 !important;
  }

  .rule-row {
    &.reject {
      background-color: #f2dede;
      // color: #a94442;
    }
    &.drop {
      background-color: #f2dede;
      // color: #a94442;
    }
  }
}

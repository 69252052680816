@import "~bootstrap/scss/bootstrap-grid";

.navbar.header-nav-transition {
  -webkit-transition:all 0.2s ease;
  -moz-transition:all 0.2s ease;
  -o-transition:all 0.2s ease;
  transition:all 0.2s ease;

  @include media-breakpoint-up(md) {
    padding: 0 200px!important;
  }


  .navbar-nav {
    justify-content: center;
  }

  a {
    font-size: 14px;

    &.active {
      color: black!important;
    }
  }

  .navbar-brand {

    img {
      width: 30px;
    }
  }

  &.floating {
    top: 30px;
    margin: 0px 20px;
    padding: 0 15px;
    color: white!important;

    @include media-breakpoint-up(md) {
      margin: 0 150px;
      padding: 0 10px!important;
    }

    a {
      font-size: 16px;
    }

  }

}
.tag-input-container {


  input {
    :focus {
    }
    // background-color: red;
    // outline: none !important;
    // border: 0;
    // border-color: inherit;
    // -webkit-box-shadow: none!important;
    box-shadow: none!important;
  }
}
.payment-pref-container {
  .card-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .card-body {
      min-height: 180px;
      min-width: 330px;
    }
  }



  .p-item {
    padding: 5px;
  }
}

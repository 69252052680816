.stripe-cardnumber-element {
  padding: 10px !important;
  font-family: "Lato", sans-serif !important;
}

.stripe-cardexpiry-element {
  padding: 10px !important;
  font-family: "Lato", sans-serif !important;
}

.stripe-cardcvc-element {
  padding: 10px !important;
  font-family: "Lato", sans-serif !important;
}

.card-form-container {
  .input-group {
    margin-top: 10px;
    // &:extend(.input-group all);
  }

  .form-control {
    // @extend .form-control;
    // min-height: 100%;
    margin-bottom: 0px;
    font-family: "Lato", sans-serif !important;

    &.child-container {
      padding: 0;
    }
  }

  .child-container {
    // .input-group;
    // .form-control;
    // height: 100%;
    padding: 10px;

    .child-item-container {
      // .make-xs-column(12);
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 0px;
    }

    .row {
      // .make-xs-column(12);
      padding-bottom: 10px;
    }
  }

  button {
    font-family: "Lato", sans-serif !important;
  }

  .child-item {
    // .make-xs-column(12);
    // .make-sm-column(8);

    // &:extend(.input-group all);
    margin-top: 10px;
    margin-bottom: 10px;

    input[type="radio"] {
      height: initial;
      width: initial;
    }

    .child-control {
      span {
        // .visible-xs;
      }

      button[type="button"] {
        // .hidden-xs;
        line-height: inherit;
        &.button-danger {
          // .btn-danger;
        }
      }
    }

    &.selected {
      .select-display {
        background-color: #337ab7;
      }
    }
  }

  .card-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    // .make-row();

    .card-valid-action {
      padding-top: 10px;
      padding-left: 15px;

      // .spinner {
      //   @include icon-spinner;
      // }
    }

    .error-container {
      // &:extend(.alert all);
      // &:extend(.alert-danger all);
      width: 100%;
    }

    .card-number {
      // .make-xs-column(12);
    }

    .card-expiration {
      // .make-xs-column(12);
      .card-expiration-item {
        // .make-xs-column(12);
        // .make-sm-column(6);
        padding: 0;
      }

      // @media (min-width: @screen-sm-min) {
      //   .card-expiration-item:first-child{
      //     padding-right: 5px;
      //   }
      //   .card-expiration-item:last-child{
      //     padding-left: 5px;
      //   }
      // }
    }

    .card-cvc {
      // .make-xs-column(12);
      // .make-sm-column(6);
      // @media (min-width: @screen-sm-min) {
      //   padding-right: 5px;
      // }
    }
  }

  .input-group-addon {
    // &:extend(.input-group-addon all);

    &.xs-hide {
      // .hidden-xs;
    }
  }

  .form-control {
    // &:extend(.form-control all);
  }

  div.form-control {
    height: 100%;
    padding-bottom: 15px;
  }
}

.modal-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-card-item {
  // .make-xs-column(12);
  // .make-sm-column(8);
  // .well;
}

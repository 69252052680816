.searchEngineContainer {

  // .spinner {
  //   @extend .spinner;
  // }

  input {
    font-family: "Lato";
  }


  // .panel-heading {
  //   // &:extend(.panel-heading all);

  //   &.space-between {
  //     display: flex;
  //     justify-content: space-between;
  //   }
  // }

  // .panel {
  //   // &:extend(.panel all);
  // }

  // .panel-body {
  //   // &:extend(.panel-body all);
  // }

  // .input-group {
  //   // &:extend(.input-group all);
  // }

  // .form-control {
  //   // &:extend(.form-control all);
  // }

  // .input-group-addon {
  //   // &:extend(.input-group-addon all);
  // }

  // .badge {
  //   // .badge;
  // }

  .date-container {
    // .make-xs-column(12);
    padding: 5px 0 10px 0;

    .date-item {
      // .input-group;
      // .make-xs-column(12);
      // .make-sm-column(6);
      padding-left: 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .searchResults {
    // display: flex;
    padding-top: 20px;
    // .make-row();

    .column {
      // .make-xs-column(12);
      // .make-sm-column(12);
      // .make-md-column(6);
      // .make-lg-column(6);
      padding: 0px;

      .panel {
        margin: 10px;
      }
    }
  }

  .icon-save {
    font-size: 20px;
    // @include icon-save;
  }

  .icon-loading {
    font-size: 20px;
    // @include icon-loading;
  }
}